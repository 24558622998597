import CookiePage from "./system/_cookiePage";

export default CookiePage

export const frontmatter = {
  pageMetaData: {
    refKey: "cookie",
    title: "Cookie policy",
    description: "We may use cookies to help improve your experience of SimpleBackups, This cookie policy is part of SimpleBackups’ privacy policy",
    author: "simplebackups.com"
  }
}